//#region ----------------------------------------------- Types

//#endregion ----------------------------------------------- Types

//#region ----------------------------------------------- Module Imports

import App from "../init/app.js"

//#endregion ----------------------------------------------- Module Imports

export default {
	execute: () => {
		App.getAppComponents().ResetPassModal.show()
	}
}
