//#region ----------------------------------------------- Types

type T = MU_AuthFormLink & {
	events: {
		"auth-form-link-clicked": {
			target: Attributes["d_target"]
			role: UserRole | undefined
		}
	}
}

type C = T["AuthFormLink"]
type E = T["events"]
type Attributes = C["attr"]

export type T_AuthFormLink = T

//#endregion ----------------------------------------------- Types

//#region ----------------------------------------------- Module Imports (only utility/helper modules)

import { TypedComponent, defineComponent, makeComponentEvent } from "haq"

//#endregion ----------------------------------------------- Module Imports (only utility/helper modules)

export class AuthFormLink extends HTMLElement {
	private Component: ReturnType<typeof TypedComponent<C>>

	private INITIALIZED = false

	constructor() {
		super()

		this.Component = TypedComponent<C>(this)
	}

	//* ---------- Initialize -----------------------------------------------

	connectedCallback() {
		if (this.INITIALIZED) return

		this.Component.addEventListener("click", () => {
			const target = this.Component.getAttribute("d_target")
			if (!target) return

			const role = this.Component.getAttribute("d_role")

			const linkEvent = makeComponentEvent<E>({
				triggerEl: this.Component,
				eventName: "auth-form-link-clicked",
				detail: { target, role: role || undefined },
				bubbles: true
			})

			linkEvent.emit()
		})

		this.INITIALIZED = true
	}

	//* ---------- Exposed Methods -----------------------------------------------

	setTarget(value: Attributes["d_target"]) {
		this.Component.setAttribute("d_target", value)
	}
}

export default function AuthFormLinkInit() {
	defineComponent<C>("auth-form-link", AuthFormLink)
}
