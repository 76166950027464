//#region ----------------------------------------------- Types

import type { T_AuthFormLink } from "@shared/ui/WebComponents/AuthFormLink/AuthFormLink"
import type { MyComponentTypes } from "haq"

type T = MU_LoginFormAdmin & {
	authFormLinkEvents: T_AuthFormLink["events"]
	globalEvents: {
		"login-form-submitted": {
			Form: X["LoginFormAdmin"]
			SubmitBtn: X["SubmitBtn"]
		}
		"reset-form-requested": undefined
	}
}

export type T_LoginForm = T

type E = T["globalEvents"]
type X = MyComponentTypes<T>

//#endregion ----------------------------------------------- Types

//#region ----------------------------------------------- Module Imports (only utility/helper modules)

import { assertCannotReach } from "front-end-helpers"
import { $, _, makeAppEvents, makeComponentEventListener } from "haq"

//#endregion ----------------------------------------------- Module Imports (only utility/helper modules)

export default function makeLoginForm() {
	let initialized = false

	const Form = _<T["LoginFormAdmin"]>("loginFormAdmin")
	const SubmitBtn = $<T["SubmitBtn"]>(Form, "[x_selector='submitBtn']")[0]

	const AppEvents = makeAppEvents<E>()

	_init()

	function _init() {
		if (initialized) return

		const AuthFormLinkListeners = makeComponentEventListener<T["authFormLinkEvents"]>(Form)
		AuthFormLinkListeners.add("auth-form-link-clicked", (e) => {
			_onAuthFormLinkClick(e.detail)
		})

		Form.addEventListener("submit", _onSubmit)

		initialized = true
	}

	function _onSubmit() {
		AppEvents.emit({
			action: "login-form-submitted",
			data: {
				Form,
				SubmitBtn
			}
		})
	}

	function _onAuthFormLinkClick({ target }: T["authFormLinkEvents"]["auth-form-link-clicked"]) {
		switch (target) {
			case "reset-password":
				AppEvents.emit({
					action: "reset-form-requested",
					data: undefined
				})
				break

			case "login":
			case "signup":
				break

			default:
				assertCannotReach(target)
		}
	}
}
