//#region ----------------------------------------------- Types

import type { GlobalActions } from "../init/event-router.js"

//#endregion ----------------------------------------------- Types

//#region ----------------------------------------------- Module Imports

import { makeFormRequest } from "front-end-helpers"

//#endregion ----------------------------------------------- Module Imports

export default {
	execute: (data: GlobalActions["login-form-submitted"]) => {
		const { Form, SubmitBtn } = data
		const formRequest = makeFormRequest({ Form, SubmitBtn })
		formRequest.init()
	}
}
