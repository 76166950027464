//#region ----------------------------------------------- Types

import type { T_LoginForm } from "@ComposedComponents/AuthForms/LoginForm"
import type { T_ResetPassModal } from "@shared/ui/ComposedComponents/ResetPassModal/ResetPassModal"

export type GlobalActions = T_LoginForm["globalEvents"] & T_ResetPassModal["globalEvents"]

type ExecutedAction<T extends keyof GlobalActions> = Readonly<{
	execute: (data: GlobalActions[T]) => void
}>

type Router = {
	[key in keyof GlobalActions]: ExecutedAction<key>
}

//#endregion ----------------------------------------------- Types

//#region ----------------------------------------------- Module Imports (only events)

import loginFormSubmitted from "../events/login-form-submitted.js"
import resetFormRequested from "../events/reset-form-requested.js"
import resetFormSubmitted from "../events/reset-form-submitted.js"

//#endregion ----------------------------------------------- Module Imports (only events)

function makeEventRouter() {
	const router: Router = {
		"login-form-submitted": loginFormSubmitted,
		"reset-form-requested": resetFormRequested,
		"reset-form-submitted": resetFormSubmitted
	}

	return Object.freeze({
		get
	})

	function get<T extends keyof Router>(action: T) {
		return router[action]
	}
}

//---------- EXPORTS

const EVENT_ROUTER = makeEventRouter()

export default EVENT_ROUTER
