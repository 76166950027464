//#region ----------------------------------------------- Types

import type { GlobalActions } from "../init/event-router.js"

//#endregion ----------------------------------------------- Types

//#region ----------------------------------------------- Module Imports

import Request from "@helpers/request.js"
import { makeFormRequest } from "front-end-helpers"
import App from "../init/app.js"

//#endregion ----------------------------------------------- Module Imports

export default {
	execute: async (data: GlobalActions["reset-form-submitted"]) => {
		const { Form, SubmitBtn, ...rest } = data
		const formRequest = makeFormRequest({ Form, SubmitBtn })
		formRequest.init()

		const response = await Request.getPostResponse("/reset-password", rest)

		const serverReponse = App.getAppComponents().ServerResponse
		if (response.success) {
			formRequest.afterResponse(true)
			serverReponse.show({
				resp: response,
				respTitle: "Success",
				respText: `An email with a password reset link has been sent to the following address:<br><br><strong><u>${response.email}</u></strong>`,
				onRedirect: "/"
			})
			App.getAppComponents().ResetPassModal.hide()
			return
		}
		formRequest.afterResponse(false)
		serverReponse.show({
			resp: response,
			respTitle: "Error",
			respText: response.error
		})
	}
}
